import React from 'react'
import { Link } from 'gatsby'

import SEO from '../components/SEO/SEO'
import Layout from '../Layout/Layout'
import Hero from '../components/Hero/Hero'
import Header from '../components/Header/Header'
import Button from '../components/Button/Button'
import Paragraph from '../components/Paragraph/Paragraph'

import Image4 from '../assets/images/pomoc-drogowa.jpeg'

const NotFoundPage = () => (
  <Layout noNav>
    <SEO
      title="404 | Podany adres nie istnieje"
      keywords={['404', 'kierownik', 'fachowiec', 'prace na wysokościach']}
    />
    <Hero image={Image4} />
    <Header>404</Header>
    <Paragraph>Panie kierowniku, coś się popsuło...</Paragraph>
    <Button as={Link} to="/">
      Strona główna
    </Button>
  </Layout>
)

export default NotFoundPage
